.nameCellWrapper {
  align-items: center;
  background-color: var(--chakra-colors-surface);
  display: flex;
  left: 0;
  position: sticky;
  z-index: var(--chakra-zIndices-docked);

  &.isSticky {
    z-index: var(--chakra-zIndices-sticky);
  }
}
