.objectAttributeBadge {
  align-items: center;
  border-radius: var(--chakra-radii-md);
  column-gap: var(--chakra-space-1);
  display: flex;
  justify-content: center;
  padding-inline-end: 0.375rem;
  padding-inline-start: 0.375rem;
  white-space: nowrap;

  &.ghost {
    background-color: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-gray-400);
  }

  &.black {
    background-color: var(--chakra-colors-gray-200);
    color: var(--chakra-colors-gray-600);
  }

  &.timeSeries {
    background-color: var(--chakra-colors-gray-200);
    color: inherit;
  }

  &.gray {
    background-color: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-gray-500);
  }

  &.error {
    background-color: var(--chakra-colors-red-100);
    color: var(--chakra-colors-red-600);
  }
}
