.pillEnhanced {
  align-items: center;
  background-color: var(--chakra-colors-surface);
  box-shadow: var(--chakra-shadows-timelinePillEnhanced);
  color: var(--chakra-colors-gray-600);
  cursor: pointer;
  display: flex;
  line-height: 1;
  padding-right: var(--chakra-space-2);

  &.isImpact {
    background-color: var(--chakra-colors-gray-100);
    box-shadow:
      var(--chakra-shadows-timelinePillEnhanced),
      0 0 0 1px var(--chakra-colors-white) inset;
  }

  &:hover {
    box-shadow:
      var(--chakra-shadows-timelinePillEnhanced),
      0 0 0 1px var(--chakra-colors-gray-400);
  }

  &.isAncestorHovered {
    box-shadow:
      var(--chakra-shadows-timelinePillEnhanced),
      0 0 0 1px var(--chakra-colors-gray-400);
  }

  &.isHidden {
    opacity: 0.3;
  }

  &.isEmpty {
    border: 1px dashed var(--chakra-colors-blue-300);
  }

  &.isActive,
  &.isAncestorSelected,
  &.isSelected {
    box-shadow:
      var(--chakra-shadows-timelinePillEnhanced),
      0 0 0 1px var(--chakra-colors-selection-500) inset,
      0 0 0 1px var(--chakra-colors-selection-500);
  }
}
