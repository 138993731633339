.attributePill {
  align-items: center;
  background-color: var(--chakra-colors-gray-300);
  border-radius: var(--chakra-radii-xl);
  display: inline-flex;
  font-weight: var(--chakra-fontWeights-medium);
  height: var(--chakra-sizes-4);
  margin-bottom: 0.125rem;
  margin-right: 0.125rem;
  margin-top: 0.125rem;
  min-width: fit-content;
  padding-bottom: 0.125rem;
  padding-inline-end: var(--chakra-space-1);
  padding-inline-start: var(--chakra-space-1);
  padding-top: 0.125rem;
}

.attributePill::after {
  content: attr(data-content);
}
