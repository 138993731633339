.ag-charts-tooltip,
.ag-charts-crosshair-label {
  background-color: rgba(0, 0, 0, 0.75) !important;
  border: 0;
  border-radius: 4px;
  color: #fff !important;
  font-family: Inter, Arial, sans-serif;
  font-size: 11px;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  pointer-events: none !important;
  z-index: var(--chakra-zIndices-overlay, 1000);
}

.ag-charts-tooltip-content {
  padding: 4px 6px;
}

.ag-charts-tooltip-heading {
  font-weight: bold;
}

// stylelint-disable-next-line selector-max-compound-selectors
.ag-charts-tooltip-symbol > svg > g > path {
  // Remove border from series color box within tooltip.
  stroke: none !important;
}

.ag-charts-crosshair-label-content {
  padding: 0 4px;
}
